// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-global-components-pages-community-community-jsx": () => import("./../src/global/components/pages/community/Community.jsx" /* webpackChunkName: "component---src-global-components-pages-community-community-jsx" */),
  "component---src-global-components-pages-content-content-page-js": () => import("./../src/global/components/pages/content/contentPage.js" /* webpackChunkName: "component---src-global-components-pages-content-content-page-js" */),
  "component---src-global-components-pages-event-event-page-jsx": () => import("./../src/global/components/pages/event/EventPage.jsx" /* webpackChunkName: "component---src-global-components-pages-event-event-page-jsx" */),
  "component---src-global-components-pages-forum-forum-page-jsx": () => import("./../src/global/components/pages/forum/ForumPage.jsx" /* webpackChunkName: "component---src-global-components-pages-forum-forum-page-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buscar-js": () => import("./../src/pages/buscar.js" /* webpackChunkName: "component---src-pages-buscar-js" */),
  "component---src-pages-eventos-js": () => import("./../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iniciar-sesion-js": () => import("./../src/pages/iniciar-sesion.js" /* webpackChunkName: "component---src-pages-iniciar-sesion-js" */),
  "component---src-pages-nuevo-js": () => import("./../src/pages/nuevo.js" /* webpackChunkName: "component---src-pages-nuevo-js" */),
  "component---src-pages-olvido-su-contrasena-js": () => import("./../src/pages/olvido-su-contrasena.js" /* webpackChunkName: "component---src-pages-olvido-su-contrasena-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-precio-js": () => import("./../src/pages/precio.js" /* webpackChunkName: "component---src-pages-precio-js" */),
  "component---src-pages-registro-js": () => import("./../src/pages/registro.js" /* webpackChunkName: "component---src-pages-registro-js" */)
}

